<template>
  <div class="my-collection">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的收藏夹</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>我的收藏夹</h3>
      <div class="collection-list">
        <div v-if="collectionList.length != 0">
          <el-table border :data="collectList">
            <el-table-column width="150" align="center" label="图片">
              <template slot-scope="scope">
                <img
                  style="width: 100%; height: 100px"
                  :src="scope.row.img"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              width="500"
              prop="goods_name"
              align="center"
              label="商品名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop=""
              label="型号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="stock"
              label="库存"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="price"
              label="单价"
            ></el-table-column>
            <el-table-column width="150" align="center" label="管理">
              <template  slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="加入购物车"
                  placement="top-start"
                >
                  <el-button
                    size="small"
                    icon="el-icon-shopping-cart-1"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="移除"
                  placement="top-start"
                >
                  <el-button
                    size="small"
                    type="danger"
                    icon="el-icon-close"
                    @click="handleDelete(scope.$index, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <img src="../../assets/logo.png" alt="" />
          <p>您还没有收藏任何商品！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../../request/http";
export default {
  name: "myCollection",
  data() {
    return {
      collectionList: [1],
      collectList: [],
    };
  },
  mounted() {
    this.getCollect();
  },
  methods: {
    // 获取收藏数量
    getCollect() {
      this.collectList = [];
      http("column.php", {
        fun: "getColumnCollect",
        module: "goods",
        type: "c",
      }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            this.collectList.push(item.goods[0]);
          });
        }
      });
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("确定要移除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        http("column.php", {
          fun: "addColumnCollect",
          bind_id: row.id,
        }).then((res) => {
          if (res.code == 0) {
            this.getCollect();
            this.$message({
              type: "success",
              message: "移除成功!",
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
:deep( .el-button--default) {
    background: #ffda00;
    border-color: #ffda00
  }
@import "src/scss/index";
.my-collection {
  @include body;
  .collection-list {
    @include flexCenter($direction: column, $justify: unset);
    & div {
      width: 100%;
    }
  }
}
</style>
